import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Tabs from '@frontend/ui-kit/Components/Tabs';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import ImportsSearch from '../../../Components/AdminPortal/EligibilityImportsSearch';
import RecentlyViewedClients from '../../../Components/AdminPortal/RecentlyViewedClients';
import PendingTerminations from '../../../Components/AdminPortal/PendingTerminations';
import AutoImportPendingReview from '../../../Components/AdminPortal/AutoImportPendingReview';
import {clearMultipleChoice, requestPendingGroupsInfo} from '../../../actions/adminPortal';
import {requestFeatureFlag} from '../../../actions/general';
import {getProfileInfo} from '../../../selectors/general';
import {equal, getEqual, getItemKeyValue, isEmpty} from '../../../utils';
import {FEATURE_FLAGS} from '../../../constants';
import './index.scss';

const ELIGIBILITY_IMPORT_TABS_VALUES = {
    import_log: 'import_log',
    pending_terminations: 'pending_terminations',
    auto_import_pending_review: 'auto_import_pending_review'
};

const ELIGIBILITY_IMPORT_TABS = [
    {key: 'Import log', value: ELIGIBILITY_IMPORT_TABS_VALUES.import_log, component: ImportsSearch},
    {key: 'Pending terminations', value: ELIGIBILITY_IMPORT_TABS_VALUES.pending_terminations, component: PendingTerminations},
    {key: 'Auto-Import Pending Review', value: ELIGIBILITY_IMPORT_TABS_VALUES.auto_import_pending_review, component: AutoImportPendingReview}
];

const EligibilityImports = () => {
    const {email: userEmail} = useSelector(getProfileInfo);
    const [activeTab, setActiveTab] = useState(0);
    const {hash} = useLocation();
    const {push} = useHistory();
    const [tabs, setTabs] = useState([]);
    const dispatch = useDispatch();
    const {component: Component} = ELIGIBILITY_IMPORT_TABS[activeTab];

    const getTabs = tabs => tabs
        .filter(getItemKeyValue('visible'))
        .map(({key, count, visited}) => (
            <span>
                {!visited && <Icon type={ICON_TYPES.circle} className='not-visited-groups mr-2'/>}
                {key}
                {!!count && <span className='ml-2'>({count})</span>}
            </span>
        ));

    useEffect(() => {
        (async () => {
            const [
                {flag: isTermination},
                {flag: isAutoImports},
                {pendingGroupsInfo}
            ] = await Promise.all([
                dispatch(requestFeatureFlag(FEATURE_FLAGS.terminationSurgeVisibility, {userEmail})),
                dispatch(requestFeatureFlag(FEATURE_FLAGS.autoimportsSchedulerV2, {userEmail})),
                dispatch(requestPendingGroupsInfo())
            ]);
            const {
                autoimport_reviews_count: autoimportReviewsCount,
                has_new_pending_reviews: isNewPendingReviews,
                pending_migration_groups_count: pendingMigrationGroupsCount,
                has_new_pending_migrations: isNewPendingMigrations,
                pending_termination_groups_count: pendingTerminationGroupsCount,
                has_new_pending_termination: isNewPendingTermination
            } = pendingGroupsInfo || {};

            const tabsProps = {
                [ELIGIBILITY_IMPORT_TABS_VALUES.import_log]: {visible: true, count: null, visited: true},
                [ELIGIBILITY_IMPORT_TABS_VALUES.pending_terminations]: {visible: isTermination, count: pendingTerminationGroupsCount, visited: !isNewPendingTermination},
                [ELIGIBILITY_IMPORT_TABS_VALUES.auto_import_pending_review]: {visible: isAutoImports, count: autoimportReviewsCount, visited: !isNewPendingReviews}
            };
            const enhancedTabs = ELIGIBILITY_IMPORT_TABS.map(tab => ({...tab, ...tabsProps[tab.value]}));

            setTabs(getTabs(enhancedTabs));
        })();
    }, [userEmail]);

    useEffect(() => {
        const tabsIndex = ELIGIBILITY_IMPORT_TABS.findIndex(getEqual(hash.slice(1), 'value'));
        const activeTabIndex = equal(tabsIndex, -1) ? 0 : tabsIndex;

        setActiveTab(activeTabIndex);
    }, [hash]);

    const onChangeTab = activeTabIndex => {
        push(`#${ELIGIBILITY_IMPORT_TABS[activeTabIndex]?.value}`);
        dispatch(clearMultipleChoice());
    };

    return (
        <React.Fragment>
            <Heading className='mb-20' type={HEADING_TYPES['1']}>Eligibility Imports</Heading>

            <RecentlyViewedClients/>

            <ContentSection className='imports-search mt-20'>
                <Tabs className='mb-10 mt-10' tabs={tabs} onChange={onChangeTab} activeTab={activeTab}/>
                {!isEmpty(tabs) && <Component isTitleVisible={false}/>}
            </ContentSection>
        </React.Fragment>
    );
};

export {EligibilityImports as TestableEligibilityImports};
export default React.memo(EligibilityImports);
